import './App.css';
import Red from './img/RedLine.png';
import Logo from './img/logobioland.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="test">
        <div className="white">
          <div className="containerh" >
          <img className="red" src={Red} alt="red" />
          <img className="logo" src={Logo} alt="bioland"/>
          <img className="red" src={Red} alt="red" />
          </div>
          
          <div className="container">
          <p className="head">UNDER</p>
          <p className="head2">CONSTRUCTION</p>
          </div>
          <p className="description">Bioland is a Lebanese established company since 2009, experts <br/>
            in aromatic herbs, trees plantation and natural essential oil <br/>
            production, operating in Lebanon and the Middle East.</p>
        </div>
        </div>
      </header>
    </div>
  );
}

export default App;
